import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { Icon } from '../../../common/icon'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage21() {
  const question = {
    title: 'Frage 21 von 35',
    headline: '',
    subline: 'Ich kann beruhigend auf Menschen/Patienten einwirken.',
  }

  const answers = [
    {
      name: 'beruhigend',
      id: 'absolut',
      value: 'Absolut!',
      desc: '',
    },
    {
      name: 'beruhigend',
      id: 'kommt-auf-person-an',
      value: 'Kommt auf die Person an.',
      desc: '',
    },
    {
      name: 'beruhigend',
      id: 'nicht-so',
      value: 'Ist nicht so meine Stärke.',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-red-circles">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="red"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="single"
              answers={answers}
              color="turkis"
              index={21}
            />
          </div>
        </section>

        <MatchingNavigation
          color="red"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-22`}
          prev={`${AREA_CARE_PATH}/spielen/gewichtung-2`}
          index={21}
        />

        <Icon position="3" name="protect" />
      </main>
    </>
  )
}
